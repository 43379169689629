import React from "react"
import { Link } from 'gatsby'
import Layout from "../components/layout";
import SEO from "../components/seo";
import { navigate } from "gatsby"
import axios from "axios"

const getPropurl = async (handler, crmid) => {
	let result = [];
	var crmId2 = "rps_ape-"+crmid;
	//instead of hit REST we use json format to get only required fields. we dont import apollo since this call from another Hook
	var data = JSON.stringify({
		"query": "query($crm_id:[String]) { properties(where: { crm_id: $crm_id}) { id, slug, search_type, department } }",
		"variables": {"crm_id": [crmId2] }
	  });
	var config = {
		method: 'post',
		url: `${process.env.GATSBY_STRAPI_SRC}/graphql`,
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`
		},
		data : data
	};
	const res = await axios(config);
	result[0] = JSON.stringify(res.data);
	handler(result);//update state var
}
const NotFoundPage = (props) => {
	const [showtemplate, setShowtemplate] = React.useState(false);
	const [propdata, setPropdata] = React.useState('');

	React.useEffect(() => {
		//we doing here as fallback, if any case build failed or not triggered then the below will be useful; when build happens redirects will happen a t netlify itself, so dont need to do CSR
		let regex = "\[a-zA-Z]{3}[0-9]{6}"; //you can modify the preg as per proj feed
		if(props?.data?.property?.publish == false) {
			return setShowtemplate(true); // show 404 page template
		}
		if(props?.location?.pathname){
			let crm_id = (props?.location?.pathname).match(regex);
			//mke sure state value is empty and we have proper url to proceed
			if(typeof crm_id != "undefined" && crm_id != null && crm_id.length > 0 && !propdata){
				getPropurl(setPropdata, crm_id[0]); // call the function to fetch data from strapi
			} else if(propdata){
				let property_data = JSON.parse(propdata);
				let property_arr = property_data.data.properties;
				if(property_arr.length > 0) {
					let property = property_arr[0];
					//set the redirection logic, same mentioned in the gatsby-node for _redirects.txt ie., crm_id to details page
					if(property.department == 'residential') {
						if(property.id) {
						  if (property.search_type == 'sales') {
							navigate(`/property-for-sale/${property.slug}-${property.id}`,{ replace: true });
						  }
						  else if (property.search_type == 'lettings') {
							navigate(`/property-to-rent/${property.slug}-${property.id}`,{ replace: true });
						  }
						}
					}
					if(property.department == 'commercial') {
						if(property.id) {
						  if (property.search_type == 'sales') {
							navigate(`/commercial-property-for-sale/${property.slug}-${property.id}`,{ replace: true });
						  }
						  else if (property.search_type == 'lettings') {
							navigate(`/commercial-property-to-rent/${property.slug}-${property.id}`,{ replace: true });
						  }
						}
					}
					if(property.department == 'new_developments') {
						if(property.id) {
						  if (property.search_type == 'sales') {
							navigate(`/new-homes-for-sale/${property.slug}-${property.id}`,{ replace: true });
						  }
						  else if (property.search_type == 'lettings') {
							navigate(`/new-homes-to-rent/${property.slug}-${property.id}`,{ replace: true });
						  }
						}
					}
				} else {
					setShowtemplate(true);// show 404 page template
				}
			} else {
				setShowtemplate(true);// show 404 page template
			}
		}
	  },[propdata]);

	return (
		<div>
		{showtemplate &&
			<div>
			<Layout>
			<SEO title="404: Not found" />
			<div className="not-found-page">
			<h1>Ooops!</h1>
			<p>We can't find the page you were looking for. This is maybe because it has been removed, we've changed its name or it is temporarily unavailable.</p>
			<Link to="/contact/" className="primary-button btn btn-primary">Contact Us</Link>
			<Link to="/" className="primary-button btn alt btn">Homepage</Link>
			</div>
			</Layout>
			</div>
		}
    </div>

    )
}
export default NotFoundPage
